<template>
  <div class="extension-container">
    <!-- navbar -->
    <qb-navbar title="拓站营销系统"></qb-navbar>
    <!-- title -->
    <qb-title title="拓站营销系统" subtitle="Quanbang Group Product"></qb-title>

    <!-- extension understand -->
    <extension-understand></extension-understand>
    <!-- extension introduction -->
    <extension-introduction></extension-introduction>
    <!-- extension-function -->
    <extension-function></extension-function>
    <!-- extension-superiority -->
    <extension-superiority></extension-superiority>
    <!-- application -->
    <qb-application></qb-application>
  </div>
</template>

<script>
import ExtensionUnderstand from './components/extension-understand.vue'
import ExtensionIntroduction from './components/extension-introduction.vue'
import ExtensionFunction from './components/extension-function.vue'
import ExtensionSuperiority from './components/extension-superiority.vue'
export default {
  name: 'Extension',
  components: {
    ExtensionUnderstand,
    ExtensionIntroduction,
    ExtensionFunction,
    ExtensionSuperiority
  }
}
</script>

<style lang="less" scoped></style>
