<template>
  <div class="extension-understand-container">
    <van-image
      :src="require('@/assets/images/extension/extension-understand.png')"
      width="100%"
      height="100%"
      class="extension-logo"
    >
      <h3 class="title">三分钟带你了解拓站系统</h3>
      <h3 class="entitle" style="margin-bottom:0.8rem">
        Three minutes to understand the extension station system
      </h3>
      <h3 class="title" style="margin-bottom:0.03rem">拓商业格局</h3>
      <h3 class="entitle" style="margin-bottom:0.37rem">Business structure</h3>
      <h3 class="title" style="margin-bottom:0.23rem">站事业巅峰</h3>
      <h3 class="entitle">Business structure</h3>
    </van-image>
    <van-image
      :src="require('@/assets/images/extension/extension-content.png')"
      width="100%"
      height="100%"
      class="extension-picture"
    >
    </van-image>
  </div>
</template>

<script>
export default {
  name: 'ExtensionUnderstand'
}
</script>

<style lang="less" scoped>
.extension-understand-container {
  margin-top: 28px;
  .extension-logo {
    margin-top: 28px;
    margin-bottom: 9px;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    line-height: 28px;
    text-align: center;
  }
  .entitle {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    text-align: center;
  }
  .extension-picture {
    display: block;
    width: 362px;
    height: 345px;
    margin: 10px auto 0;
  }
}
</style>
