<template>
  <div class="extension-superiority-container">
    <h3 class="title">六大优势</h3>
    <div class="line"></div>
    <div class="entitle">Six major advantages</div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-item" v-for="item in 6" :key="item">
        <img
          width="100%"
          height="100%"
          :src="
            require(`@/assets/images/extension/extension_advantage_${item}.png`)
          "
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'ExtensionFunction',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loopedSlides: 5,
        spaceBetween: 32,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 1000
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.extension-superiority-container {
  padding-top: 82px;
  .title {
    font-size: 20px;
    font-weight: 700;
    color: #010343;
    line-height: 28px;
    text-align: center;
  }
  .line {
    width: 375px;
    border-bottom: 2px solid #010343;
    margin-top: 7px;
    margin-bottom: 1px;
  }
  .entitle {
    font-size: 16px;
    font-weight: 700;
    color: #010343;
    line-height: 22px;
    text-align: center;
  }
  .swiper {
    margin-top: 40px;
    .swiper-item {
      width: 168px;
      height: 245px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      border-radius: 22px;
    }
  }
}
</style>
