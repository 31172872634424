<template>
  <div class="extension-introduction-container">
    <h3 class="title">泉邦拓站</h3>
    <ul>
      <li v-for="item in questionList" :key="item.id" class="question-answer">
        <div class="question">
          <span class="question-index">{{ item.id }}</span>
          <div class="question-content">{{ item.question }}</div>
        </div>
        <div class="answer" v-html="item.answer"></div>
      </li>
    </ul>
    <ul>
      <li v-for="item in systemList" :key="item.id" class="system">
        <h3 class="system-title">{{ item.title }}</h3>
        <div class="system-line"></div>
        <div class="system-content">{{ item.content }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ExtensionIntroduction',
  data() {
    return {
      questionList: [
        {
          id: 1,
          question: '什么是拓站？',
          answer:
            '工欲善其事，必先利其器，拓站系统是一款让你轻松营销，简单盈利的商业模式量化产品系统，以最先进的数据处理系统结合丰富的实战案例经验，根据店铺的实际经营情况和发展阶段，一站式解决实体店经营困境，实现轻松躺赚。'
        },
        {
          id: 2,
          question: '拓站能为你解决什么问题？',
          answer:
            '拓站系统作为一款专为线下店铺量身定制的营销利器；全方位地为您解决客流不足、转化率低、渠道单一、内部管理及消费升级等常见问题，还可以帮您定制各种有效的营销方案，更可以解决连锁加盟管理难题；帮助店铺实现渠道多元化、数据可视化、盈利最大化。'
        },
        {
          id: 3,
          question: '怎么使用拓站系统？',
          answer:
            '1.在咨询的过程中将店铺所面临的问题，想要达成的目标，真实集中地反馈，做到有的放矢；<br />2.根据咨询师的定制方案及实施步骤，有效执行推进；<br />3.实时反馈执行进程，取得初步成果后，设立下一阶段目标。<br />'
        }
      ],
      systemList: [
        {
          id: 1,
          title: '01 营销裂变系统',
          content:
            '会员储值、助力众筹、扭蛋抽奖、口碑排行、套餐卡… 无限量营销裂变工具和方案组合供你选择，强大的咨询服务团队支持，持续不断迭代。'
        },
        {
          id: 2,
          title: '02 团队激励系统',
          content:
            '通过数据化分析，激发员工竞争，明确门店问题，清晰工作目标，最大化激发员工主观能动性，让员工和老板一样思考。'
        },
        {
          id: 3,
          title: '03 合伙人激励系统',
          content:
            '合伙人分级别管理、权益商品赠送激励；各种组合方案激发合伙人转介绍，明确客户归属，账单报告一键生成，节约管理服务成本。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.extension-introduction-container {
  padding: 53px 20px 0;
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #010343;
    line-height: 33px;
    margin-bottom: 28px;
  }
  .question-answer {
    margin-bottom: 52px;
  }
  .question-answer:nth-last-child {
    margin-bottom: 0px;
  }
  .question {
    margin-bottom: 16px;
    .question-index {
      display: inline-block;
      width: 42px;
      height: 64px;
      line-height: 64px;
      background: #1651dd;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      vertical-align: top;
    }
    .question-content {
      display: inline-block;
      width: 293px;
      height: 64px;
      background: #f7f9fd;
      font-size: 20px;
      font-weight: 500;
      color: #010343;
      line-height: 64px;
      padding-left: 19px;
      vertical-align: top;
    }
  }
  .answer {
    background: #f7f9fd;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #010343;
    line-height: 25px;
    padding: 31px 0;
  }
  .system {
    .system-title {
      font-size: 24px;
      font-weight: 700;
      color: #010343;
      line-height: 33px;
      margin-top: 35px;
    }
    .system-line {
      width: 333px;
      height: 4px;
      background: #1651dd;
      margin-bottom: 29px;
    }
    .system-content {
      background: #f7f9fd;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      padding: 34px 14px 29px 16px;
    }
  }
}
</style>
